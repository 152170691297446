import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CofryApiService {
  cofryUrl: string = "https://www.cofry.com.br/partner/check";
  cofryClientToken: string = "706f1b81c7f411ec94a012254384ef53";
  
  constructor(
    public api: ApiService
  ) { }

  getToken(user){
    var body:any = {
      'token': this.cofryClientToken,
      'email': user.email,
      'nome':user.first_name,
      'doc':user.cpf,
      'e-mail': user.email,
    };

    return this.api.post('/cofry-api',body).toPromise();
  }
}