import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IonRefresher } from "@ionic/angular";
import { Subscription } from "rxjs";
import { CategoriasConteudosContentComponent } from "src/app/components/categorias-conteudos-content/categorias-conteudos-content.component";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { PdfsService } from "src/app/services/pdfs.service";

type DefaultTab =
  | "categorias"
  | "favoritos"
  | "pdfs-e-dicas"
  | "contato"
  | "categorias-conteudos"
  | "";
@Component({
  templateUrl: "default-tabs.html",
})
export class DefaultTabsPage implements AfterViewInit, OnDestroy {
  @ViewChild(IonRefresher) refresher: IonRefresher;
  @ViewChild(CategoriasConteudosContentComponent)
  categoriasConteudosContent: CategoriasConteudosContentComponent;
  tab: DefaultTab = "categorias";
  headerTitle = "Home";

  subscriptionCategoriasConteudos: Subscription;
  queryParamsSub: Subscription;
  constructor(
    public pageParams: PageParamsService,
    public _pdfs: PdfsService,
    public aplicativos: AplicativosService,
    public _categorias: CategoriasService,
    public route: ActivatedRoute
  ) {
    this._pdfs.getAllIfNecessary();
  }
  ionViewDidEnter() {
    this._categorias.getAll({ completo: false });
  }
  ngAfterViewInit() {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.setTab(params.tab);
      } else {
        this.setTab("categorias");
      }
    });
  }
  ngOnDestroy() {
    if (this.subscriptionCategoriasConteudos) {
      this.subscriptionCategoriasConteudos.unsubscribe();
    }
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }
  onRefresher() {
    const currentTab = this.tab;
    if (currentTab === "categorias-conteudos") {
      this.categoriasConteudosContent.getConteudos();
    } else if (currentTab !== "categorias") {
      this.tab = "";
    }
    setTimeout(() => {
      this.tab = currentTab;
      if (this.tab !== "categorias-conteudos") {
        setTimeout(() => this.refresher.complete(), 500);
      }
    }, 50);
  }
  setTab(tab: DefaultTab) {
    this.tab = tab;
    if (this.tab === "categorias-conteudos") {
      setTimeout(() => {
        this.categoriasConteudosContent.onRequestEnd.subscribe(() => {
          this.headerTitle = this._categorias.current?.titulo;
          this.refresher.complete();
        });
      }, 30);
    } else {
      if (this.subscriptionCategoriasConteudos) {
        this.subscriptionCategoriasConteudos.unsubscribe();
      }
    }
    if (this.tab === "favoritos") {
      this.headerTitle = "FAVORITOS";
    }
    if (this.tab === "categorias") {
      this.headerTitle = "EASYLIFE";
    }
    if (this.tab === "pdfs-e-dicas") {
      this.headerTitle = this._pdfs.list.length ? "CONTEÚDOS E DICAS" : "DICAS";
    }
    if (this.tab === "contato") {
      this.headerTitle = "CONTATO";
    }
  }
}
